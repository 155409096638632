





















































































import { Component, Vue } from "vue-property-decorator";
import {
  ArrowLeftIcon,
  RefreshCcwIcon,
  EditIcon,
  BoxIcon,
} from "vue-feather-icons";
import AssetPreview from "../components/assets-detail/Asset-Preview.vue";
import AssetDashboard from "../components/assets-detail/tabs/Asset-Dashboard.vue";
import AssetLeaseHistory from "../components/assets-detail/tabs/Asset-LeaseHistory.vue";
import AssetHistory from "../components/assets-detail/tabs/Asset-History.vue";
import AssetDocuments from "../components/assets-detail/tabs/Asset-Documents.vue";
import AssetPhotos from "../components/assets-detail/tabs/Asset-Photos.vue";
import AssetMaintenance from "../components/assets-detail/tabs/Asset-Maintenance.vue";

import Loader from "../components/ui/Loader.vue";
import NoData from "../components/ui/No-Data.vue";
// import CommingSoon from "../components/ui/Comming-Soon.vue";

@Component({
  components: {
    ArrowLeftIcon,
    RefreshCcwIcon,
    AssetPreview,
    AssetDashboard,
    AssetLeaseHistory,
    AssetHistory,
    AssetDocuments,
    AssetPhotos,
    AssetMaintenance,
    Loader,
    NoData,
    EditIcon,
    // CommingSoon,
    BoxIcon,
  },
})
export default class AssetDetail extends Vue {
  isLoading = true;
  showNoData = false;
  noDataType = "Error";
  basicDetailData = "";
  selectedTab = 0;

  mounted() {
    this.fetchAssetWithId();
  }

  reloadData() {
    this.isLoading = true;
    this.showNoData = false;
    this.fetchAssetWithId();
    this.selectedTab = 0;
  }

  onChangeTab(index: number) {
    this.selectedTab = index;
  }

  async fetchAssetWithId() {
    const id = this.$router.currentRoute.params.id;
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/list/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.basicDetailData = data;
      } else {
        this.basicDetailData = "";
        this.showNoData = true;
      }
    } catch (e) {
      this.basicDetailData = "";
      this.showNoData = true;
    } finally {
      this.isLoading = false;
    }
  }
}
