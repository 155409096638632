
































import { Component, Prop, Vue } from "vue-property-decorator";
import { TrashIcon } from "vue-feather-icons";

@Component({
  components: {
    TrashIcon,
  },
})
export default class PdfViewer extends Vue {
  @Prop() PdfList!: Array<{
    id: string;
    fileName: string;
    url: string;
  }>;
  @Prop() onDeleteItem!: Function;

  @Prop() isMaintenanceMode: any = "false";
}
