var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"intro-y tab-content mt-5"},[_c('div',{staticClass:"tab-content__pane active"},[_c('div',{staticClass:"grid grid-cols-12 gap-6"},[(_vm.isLoading)?_c('div',{staticClass:"col-span-12"},[(_vm.isLoading)?_c('Loader'):_vm._e()],1):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"intro-y box col-span-12"},[_c('div',{staticClass:"flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5"},[_c('h2',{staticClass:"font-medium text-base mr-auto"},[_vm._v("Asset Maintenance")]),_c('button',{staticClass:"button border relative items-center text-gray-700 dark:border-dark-5 dark:text-gray-300 hidden sm:flex",on:{"click":function($event){return _vm.$router.push({
                path: '/asset-tracker/new-maintenance',
                query: { id: _vm.id },
              })}}},[_c('PlusIcon',{staticClass:"w-4 h-4 mr-2"}),_vm._v(" Add Maintenance ")],1)]),_c('div',{staticClass:"px-1"},[(_vm.assetsList.length !== 0)?_c('div',{staticClass:"intro-y overflow-auto lg:overflow-visible"},[_c('table',{staticClass:"table table-report sm:mt-2"},[_vm._m(0),_c('tbody',_vm._l((_vm.assetsList),function(asset,index){return _c('tr',{key:index,staticClass:"intro-x zoom-in"},[_c('td',{on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/maintenance/" + (asset.id)),
                      })}}},[_c('span',{staticClass:"font-medium whitespace-no-wrap"},[_vm._v(_vm._s(asset.title))]),_c('div',{staticClass:"text-gray-600 text-xs whitespace-no-wrap"},[_vm._v(" "+_vm._s(asset.description)+" ")])]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/maintenance/" + (asset.id)),
                      })}}},[_c('span',{staticClass:"font-medium whitespace-no-wrap bg-theme-9 text-white text-xs rounded px-2 py-1"},[_vm._v(_vm._s(asset.scheduled_date))])]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/maintenance/" + (asset.id)),
                      })}}},[_c('span',{staticClass:"font-medium whitespace-no-wrap bg-theme-6 text-white text-xs rounded px-2 py-1"},[_vm._v(_vm._s(asset.completion_date))])]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/maintenance/" + (asset.id)),
                      })}}},[_vm._v(" "+_vm._s(asset.status)+" ")]),_c('td',{staticClass:"w-40",on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/maintenance/" + (asset.id)),
                      })}}},[_c('div',{staticClass:"flex items-center justify-center",class:{
                        'text-theme-6': asset.type === 'emergency',
                        'text-theme-9': asset.type === 'regular',
                      }},[_vm._v(" "+_vm._s(asset.type)+" ")])]),_c('td',{staticClass:"table-report__action w-56"},[_c('div',{staticClass:"flex flex-col justify-center items-center"},[_c('div',{staticClass:"flex items-center justify-center text-theme-6 cursor-pointer",on:{"click":function($event){return _vm.onDeleteMaintenance(asset.id)}}},[(!_vm.isDeleting)?_c('TrashIcon',{staticClass:"w-4 h-4 mr-2"}):_vm._e(),(_vm.isDeleting)?_c('svg',{staticClass:"animate-spin -ml-1 mr-3 h-5 w-5 text-theme-6",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24"}},[_c('circle',{staticClass:"opacity-25",attrs:{"cx":"12","cy":"12","r":"10","stroke":"currentColor","stroke-width":"4"}}),_c('path',{staticClass:"opacity-75",attrs:{"fill":"currentColor","d":"M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"}})]):_vm._e(),_vm._v(" "+_vm._s(_vm.isDeleting ? "Deleting" : "Delete")+" ")],1)])])])}),0)])]):_vm._e()])]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"whitespace-no-wrap"},[_vm._v("TITLE & DESCRIPTION")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v(" SCHEDULED DATE ")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v(" COMPLETION DATE ")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("STATUS")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("TYPE")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("ACTION")])])])}]

export { render, staticRenderFns }