var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"intro-y tab-content mt-5"},[_c('div',{staticClass:"tab-content__pane active"},[_c('div',{staticClass:"grid grid-cols-12 gap-6"},[(_vm.isLoading)?_c('div',{staticClass:"col-span-12"},[(_vm.isLoading)?_c('Loader'):_vm._e()],1):_vm._e(),(!_vm.isLoading)?_c('div',{staticClass:"intro-y box col-span-12"},[_vm._m(0),_c('div',{staticClass:"px-1"},[(_vm.assetsList.length !== 0)?_c('div',{staticClass:"intro-y overflow-auto lg:overflow-visible"},[_c('table',{staticClass:"table table-report sm:mt-2"},[_vm._m(1),_c('tbody',_vm._l((_vm.assetsList),function(asset,index){return _c('tr',{key:index,staticClass:"intro-x zoom-in"},[_c('td',{on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/lease/" + (asset.id)),
                      })}}},[_vm._v(" "+_vm._s(asset.register_no)+" ")]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/lease/" + (asset.id)),
                      })}}},[_vm._v(" "+_vm._s(asset.customer)+" ")]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/lease/" + (asset.id)),
                      })}}},[_vm._v(" "+_vm._s(asset.total_claimable_amount)+" ")]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/lease/" + (asset.id)),
                      })}}},[_c('span',{staticClass:"py-1 px-2 rounded text-xs bg-theme-1 text-white"},[_vm._v(_vm._s(asset.rental_start_date))])]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/lease/" + (asset.id)),
                      })}}},[_c('span',{staticClass:"py-1 px-2 rounded text-xs bg-theme-11 text-white"},[_vm._v(_vm._s(asset.rental_end_date))])]),_c('td',{staticClass:"text-center",on:{"click":function($event){return _vm.$router.push({
                        path: ("/asset-tracker/lease/" + (asset.id)),
                      })}}},[_c('span',{class:{
                        'text-theme-9': asset.status == 'active',
                        'text-theme-6': asset.status == 'inactive',
                      }},[_vm._v(_vm._s(asset.status == "active" ? "Active" : "Inactive"))])])])}),0)])]):_vm._e()])]):_vm._e()])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center px-5 py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5"},[_c('h2',{staticClass:"font-medium text-base mr-auto"},[_vm._v("Lease History")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticClass:"whitespace-no-wrap"},[_vm._v("CUSTOMER PO NO.")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("CUSTOMER")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("TOTAL AMOUNT")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("START DATE")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("END DATE")]),_c('th',{staticClass:"text-center whitespace-no-wrap"},[_vm._v("STATUS")])])])}]

export { render, staticRenderFns }