









































































































import { Component, Prop, Vue } from "vue-property-decorator";
import {
  CircleIcon,
  TagIcon,
  AwardIcon,
  StarIcon,
  MapPinIcon,
  MapIcon,
  ListIcon,
  UserIcon,
  BarChart2Icon,
} from "vue-feather-icons";

@Component({
  components: {
    CircleIcon,
    TagIcon,
    AwardIcon,
    StarIcon,
    MapPinIcon,
    MapIcon,
    ListIcon,
    UserIcon,
    BarChart2Icon,
  },
})
export default class AssetPreview extends Vue {
  @Prop() basicDetailData!: any;
  @Prop() onChangeTab!: Function;

  activeTab = 0;
  tabs = [
    "Dashboard",
    // "Lease",
    "Photos",
    "Documents",
    "Maintenance",
    "History",
  ];

  onClickTab(index: number) {
    this.activeTab = index;
    this.onChangeTab(index);
  }

  status: any = "";
  mounted() {
    this.fetchAssetStatus(this.basicDetailData.id);
  }

  async fetchAssetStatus(id: string) {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/telematics/device-status/${id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status == 200) {
        const status = await response.json();
        this.status = status.status;
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }
}
