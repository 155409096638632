

































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import { PlusIcon, TrashIcon } from "vue-feather-icons";
import Loader from "../../ui/Loader.vue";

@Component({
  components: {
    PlusIcon,
    Loader,
    TrashIcon,
  },
})
export default class AssetMaintenance extends Vue {
  @Prop() id!: string;
  assetsList: any = [];
  isLoading = false;

  mounted() {
    this.fetchMaintenanceListByAsset();
  }
  async fetchMaintenanceListByAsset() {
    try {
      this.isLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/maintenance/asset/${this.id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );

      if (response.status === 200) {
        this.assetsList = await response.json();
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  }

  isDeleting = false;
  async onDeleteMaintenance(id: string) {
    try {
      this.isDeleting = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/maintenance/${id}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );

      if (response.status === 200) {
        this.$store.commit("showToastMessage", {
          boldText: "Successfully!",
          text: "Maintenance deleted",
          type: "Success",
        });
        this.fetchMaintenanceListByAsset();
      } else {
        this.$store.commit("showToastMessage", {
          boldText: "Failed!",
          text: "Something went wrong",
          type: "Danger",
        });
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isDeleting = false;
    }
  }
}
