
























































































































































































































































































































































































































































































































































































































































































































































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class AssetDashboard extends Vue {
  @Prop() basicDetailData!: any;

  mounted() {
    this.fetchCreatedByEmployee();
  }

  createdBy = "";
  async fetchCreatedByEmployee() {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://${this.$store.state.ROOT_URL}/employee-profile`,
        {
          method: "POST",
          credentials: "include",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            eid: this.basicDetailData.created_by.employee_id,
          }),
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.createdBy = data.first_name + " " + data.last_name;
      }
    } catch (e) {
      console.log(e);
    }
  }
}
