





























































import { Component, Prop, Vue } from "vue-property-decorator";
import { PlusIcon } from "vue-feather-icons";
import PdfViewer from "../Pdf-Viewer.vue";
import Loader from "../../ui/Loader.vue";

@Component({
  components: {
    PlusIcon,
    PdfViewer,
    Loader,
  },
})
export default class AssetDocuments extends Vue {
  @Prop() id!: string;

  mounted() {
    this.fetchAllAssetDouments();
  }

  isLoading = false;
  async fetchAllAssetDouments() {
    try {
      this.isLoading = true;
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/doc/${this.id}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.status == 200) {
        const data = await response.json();
        this.documentList = data.map((x: any) => ({
          id: x.id,
          fileName: x.name,
          url: x.url,
        }));
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isLoading = false;
    }
  }

  $refs!: {
    fileInput: HTMLInputElement;
  };
  onAddDocument() {
    this.$refs.fileInput.click();
  }

  documentList: Array<{
    fileName: string;
  }> = [];
  isUploading = false;
  appDocumentUpload(e: any) {
    if (e.target.files.length > 0) {
      if (
        e.target.files[0].type === "application/pdf" ||
        e.target.files[0].type === "application/doc"
      ) {
        this.uploadDocToServer(e.target.files[0]);
      }
    } else {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: "No file selected",
        type: "Danger",
      });
    }
  }

  async uploadDocToServer(doc: any) {
    try {
      this.isUploading = true;
      const formData = new FormData();
      formData.append("name", doc.name);
      formData.append("asset_id", this.id);
      formData.append("file", doc);
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/doc`,
        {
          method: "POST",
          credentials: "include",
          body: formData,
        }
      );
      if (response.status == 200) {
        this.fetchAllAssetDouments();
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    } finally {
      this.isUploading = false;
    }
  }

  async onDeleteAssetDocument(id: string) {
    try {
      const response = await fetch(
        `${this.$store.state.PROTOCOL}://api.assettracker.${this.$store.state.ROOT_URL}/asset/doc/${id}`,
        {
          method: "DELETE",
          credentials: "include",
        }
      );

      if (response.status === 200) {
        this.$store.commit("showToastMessage", {
          boldText: "Successfully!",
          text: "Deleted asset docuement",
          type: "Success",
        });
        this.fetchAllAssetDouments();
      } else {
        this.$store.commit("showToastMessage", {
          boldText: "Error!",
          text: "Something went wrong",
          type: "Danger",
        });
      }
    } catch (e) {
      this.$store.commit("showToastMessage", {
        boldText: "Error!",
        text: e.message,
        type: "Danger",
      });
    }
  }
}
