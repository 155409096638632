








































import { Component, Prop, Vue } from "vue-property-decorator";
import { RefreshCcwIcon } from "vue-feather-icons";

@Component({
  components: {
    RefreshCcwIcon,
  },
})
export default class NoData extends Vue {
  @Prop() onReloadData!: Function;
  @Prop() type!: "Empty" | "Error";
}
