




























import { Component, Prop, Vue } from "vue-property-decorator";
import { TrashIcon } from "vue-feather-icons";

@Component({
  components: {
    TrashIcon,
  },
})
export default class PhotoViewer extends Vue {
  @Prop() photoList!: Array<{
    id: string;
    fileName: string;
    pictureSrc: any;
  }>;
  @Prop() onRemovePhoto!: Function;

  @Prop() isMaintenanceMode: any = "false";
}
